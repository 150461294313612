// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyAqpn1_VJ4U0Ae074budcqA0VDsBbkemLU",
  authDomain: "yvomrknew.firebaseapp.com",
  databaseURL: "https://yvomrknew-default-rtdb.firebaseio.com",
  projectId: "yvomrknew",
  storageBucket: "yvomrknew.appspot.com",
  messagingSenderId: "249077468470",
  appId: "1:249077468470:web:2ff83059287d599bdc7797",
  measurementId: "G-ZM3Y00C882"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };